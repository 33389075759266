import { useEffect, useState } from "react";
import { Header } from "./Header";
import { Loading } from "./Loading";
import { GetOrderByUUID } from "../services/get_order";
import { OrderDetail } from "./OrderDetail";
import { CancelPopup } from "./CancelPopup/CancelPopup";
import { UpdateCodApprovalStatus } from "../services/update_cod_approval";
import { Localized } from "../util/localization";

function handleApprovalApiResponse(response, err, setOrderData, setApiError, locale) {
    console.log('response: ')
    console.log(response)
    if (err) {
        console.log('err: ')
        console.log(err);
        setApiError(Localized.SOME_ERR.value(locale, "Some error occured"))
        return
    }
    if (response && response.updated === true) {
        console.log('cod order is updated')
        if (response.approval_status !== null && response.data !== null) {
            setOrderData(response.data)
        } else {
            console.log('need to reload')
            window.location.reload();
        }
    } else {
        console.log('some error')
        setApiError(Localized.SOME_ERR.value(locale, "Some error occured"))
        return
    }
}

export function AcceptanceForUUID({ uuid, tenant_id, access_token, support_number }) {

    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [locale, setLocale] = useState('en');
    const [apiError, setApiError] = useState(null);
    const [showCancelPopup, setShowCancelPopup] = useState(false);

    useEffect(() => {
        
        console.log("loading order data", uuid);
        if (uuid == null || uuid == ''){
            return;
        }
        setLoading(true);
        setApiError(null);
        GetOrderByUUID(uuid, tenant_id, access_token, (data, err) => {
            if (err) {
                console.log(`error get order by uuid ${err}`);
                setApiError(Localized.LOAD_ERR.value(locale, "Some error occured loading", {
                    '[support_number]': support_number ?? 'NA'
                }));
            } else {
                setLocale(data.locale)
                setOrderData(data);
                setApiError(null);
            }
            setLoading(false);
        });

    }, [access_token, tenant_id, uuid,  locale]);

    return (
        <div className="scrollY" dir={ locale === "ar" ? "rtl": "ltr" }>
            <Header locale={locale} />

            {orderData && <OrderDetail
                locale={locale}
                detail={orderData}
                onAccept={() => {
                    setApiError(null);
                    setLoading(true);
                    UpdateCodApprovalStatus(uuid, tenant_id, access_token, true, null, null, null, (response, err) => {
                        setLoading(false);
                        handleApprovalApiResponse(response, err, setOrderData, setApiError, locale)
                    })
                }}
                onReject={() => {
                    setApiError(null);
                    setShowCancelPopup(true);
                }} />
            }

            {showCancelPopup && <CancelPopup
                locale={locale}
                rejection_reasons={orderData.rejection_reasons}
                dismiss={() => {
                    setShowCancelPopup(false)
                }}
                onCancelOrderClick={(selectedReason, notes) => {
                    setApiError(null);
                    setLoading(true);
                    UpdateCodApprovalStatus(
                        uuid, tenant_id, access_token,
                        false, selectedReason.name,
                        selectedReason.ob_code, notes,
                        (response, err) => {
                            setLoading(false);
                            handleApprovalApiResponse(response, err, setOrderData, setApiError, locale)
                            setShowCancelPopup(false)
                        }
                    );
                }}
            />}

            { apiError && <div className="dimBackView" style={{
                position: "absolute", top: 0, right: 0, bottom: 0, left: 0,
            }}>
                <div className="card" style={{
                    width: "40%",
                    minWidth: "320px",
                    padding: "20px"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <span style={{
                            marginBottom: "30px"
                        }}>{apiError}</span>
                        <button 
                            className="btn ThemeGrid_MarginGutter"
                            onClick={()=>{
                                window.location.reload();
                            }}
                        >
                            {Localized.RELOAD.value(locale, "Reload")}
                        </button>
                    </div>

                </div>

            </div>}

            {loading && <div className="dimBackView" style={{
                position: "absolute", top: 0, right: 0, bottom: 0, left: 0,
            }}>
                <Loading locale={locale} />
            </div>}
        </div>
    );
}